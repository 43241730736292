import React from "react"

import * as s from "./intro.module.sass"

const Intro = () => {
  const diversity = DIVERSITY.map((glyph) => (
    <span key={glyph.color} style={{ color: glyph.color }}>
      {glyph.letter}
    </span>
  ))
  return (
    <div className={s.component}>
      <div className="container">
        <div className={s.title}>{diversity}</div>

        <div className={s.content}>
          <p>
            We believe in <strong>transparency</strong>,{" "}
            <strong>honesty</strong> & being held to <strong>account</strong>.
          </p>
          <p>
            Although we're still early & have many mistakes left to make; we
            want to <strong>lead by example</strong>.
          </p>
          <p>
            We'll keep this page <strong>updated</strong>.
          </p>
        </div>
      </div>
    </div>
  )
}

const DIVERSITY = [
  { letter: "d", color: "#EB2869" },
  { letter: "i", color: "#EB28CA" },
  { letter: "v", color: "#AA28EB" },
  { letter: "e", color: "#6928EB" },
  { letter: "r", color: "#288AEB" },
  { letter: "s", color: "#26DEDE" },
  { letter: "i", color: "#28EB8A" },
  { letter: "t", color: "#99E600" },
  { letter: "y", color: "#FFBF00" },
]

export default Intro
