import React from "react"

import Title from "components/elements/title"
import Hr from "components/elements/hr"
import ContactButton from "components/form/contact-button"

import cx from "classnames"
import * as ss from "./shared.module.sass"
import * as s from "./more.module.sass"

const More = () => {
  return (
    <div className={s.component}>
      <div className="container">
        <Title parentClass={cx(ss.title, s.title)}>
          <span>
            If you want more information, to talk to us or if you have
            suggestions, please get in touch!
          </span>
        </Title>

        <Hr parentClass={s.hr} />

        <ContactButton />
      </div>
    </div>
  )
}

export default More
