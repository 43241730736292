import React from "react"

import Title from "components/elements/title"
import Hr from "components/elements/hr"
import RetinaImage from "components/media/retina-image"

import cx from "classnames"
import * as ss from "./shared.module.sass"
import * as s from "./world-map.module.sass"

const WorldMap = () => {
  const imageUrl = process.env.SITE_URL + "/images/diversity/world-map"
  return (
    <div className={s.component}>
      <div className="container">
        <Title parentClass={cx(ss.title, s.title)}>
          <span>This is where we're from</span>
        </Title>

        <Hr parentClass={s.hr} />

        <RetinaImage path={imageUrl} width={1486} height={744} />

        <div className={cx(ss.content, s.content)}>
          <p>
            We're only small - but our reach is huge! We decided early on that being able to sponsor Tier 2 Visas was
            going to be a really important part of becoming a diverse studio. We're still growing - and we're trying our hardest to work with people from all over the world.
          </p>
          <p>
            Map includes contractors & external collaborators - we try as hard as possible to make everyone feel at home!
          </p>
        </div>
      </div>
    </div>
  )
}

export default WorldMap
