import React from "react"

import Buttons from "components/form/buttons"
import Button from "components/form/button"

import * as s from "./contact-button.module.sass"

const ContactButton = () => {
  const encodedSubject = `Hello Netspeak Games!`
  const mailto = `mailto:support@netspeakgames.com?subject=${encodedSubject}`

  return (
    <Buttons parentClass={s.buttons}>
      <Button parentClass={s.button} color="pink" href={mailto}>
        support@netspeakgames.com
      </Button>
    </Buttons>
  )
}

export default ContactButton
