import React from "react"
import { Doughnut } from "react-chartjs-2"

import Title from "components/elements/title"
import Hr from "components/elements/hr"

import cx from "classnames"
import * as ss from "./shared.module.sass"
import * as s from "./gender.module.sass"

const Gender = () => {
  const data = {
    datasets: [
      {
        data: [8, 7, 0],
        backgroundColor: ["#AA28EB", "#EB28CA", "#26DEDE"],
      },
    ],
    labels: ["Male", "Female", "Non binary"],
  }

  const options = {
    aspectRatio: 1,
  }

  return (
    <div className={s.component}>
      <div className="container">
        <div className={s.body}>
          <div className={s.pie}>
            <Doughnut data={data} options={options} height={640} width={640} />
          </div>

          <div className={s.text}>
            <Title parentClass={cx(ss.title, s.title)}>
              <span>An inclusive culture for all</span>
            </Title>

            <Hr parentClass={s.hr} isLeft />

            <div className={cx(ss.content, s.content)}>
              <p>
                At Netspeak Games, inclusion and diversity means bringing
                everybody in. We welcome all voices and all beliefs.
              </p>
              <p>
                Through their experiences, they bring leadership, technical
                skills, and a spirit of collaboration to Netspeak Games.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gender
