import React from "react"
import { Helmet } from "react-helmet"

import Layout from "layouts/default"
import Header from "components/components/header"
import Intro from "components/diversity/intro"
import Gender from "components/diversity/gender"
import WorldMap from "components/diversity/world-map"
import More from "components/diversity/more"

const DiversityPage = () => (
  <Layout>
    <Helmet>
      <title>Netspeak Games Diversity</title>
    </Helmet>
    <Header version="index" />
    <Intro />
    <Gender />
    <WorldMap />
    <More />
  </Layout>
)

export default DiversityPage
