import React from "react"

const RetinaImage = (props) => {
  const {
    path,
    width = "auto",
    alt = "Image",
    height = "auto",
    extension = "png",
  } = props
  const srcset = `${path}.${extension} 1x, ${path}@2x.${extension} 2x, ${path}@3x.${extension} 3x`

  return (
    <img
      src={`${path}.${extension}`}
      srcSet={srcset}
      alt={alt}
      width={width}
      height={height}
    />
  )
}

export default RetinaImage
